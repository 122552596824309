import React, { useState, useEffect } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import api from '../sharedComponents/api';

const AdminWebsites = () => {
    const { t } = useTranslation();
    const [websites, setWebsites] = useState([]);
    const [editingId, setEditingId] = useState(null);  // Track the website being edited
    const [newLogo, setNewLogo] = useState('');        // Track the new logo URL input
    const [error, setError] = useState('');
    const placeholderLogo = 'https://via.placeholder.com/50';

    useEffect(() => {
        const fetchWebsites = async () => {
            try {
                const response = await api.get('/admin/websites');
                setWebsites(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error(t('errorFetchingWebsites'), error);
                setError(t('errorFetchingWebsites'));
            }
        };

        fetchWebsites();
    }, [t]);

    const handleEditClick = (websiteId, currentLogo) => {
        setEditingId(websiteId);
        setNewLogo(currentLogo || '');  // Set the current logo as the initial value
    };

    const handleSaveClick = async (websiteId) => {
        try {
            await api.put(`/admin/websites/${websiteId}`, { logo: newLogo });
            setWebsites(websites.map(w => w.id === websiteId ? { ...w, logo: newLogo } : w));  // Update the logo locally
            setEditingId(null);  // Exit edit mode
        } catch (error) {
            console.error('Error updating logo:', error);
            setError(t('errorUpdatingLogo'));
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                {t('adminWebsites')}
            </Typography>

            {error && <Typography variant="h6" color="error">{error}</Typography>}

            {websites.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('id')}</TableCell>
                                <TableCell>{t('logo')}</TableCell>
                                <TableCell>{t('name')}</TableCell>
                                <TableCell>{t('url')}</TableCell>
                                <TableCell>{t('actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {websites.map((website) => (
                                <TableRow key={website.id}>
                                    <TableCell>{website.id}</TableCell>
                                    <TableCell>
                                        {editingId === website.id ? (
                                            <TextField
                                                value={newLogo}
                                                onChange={(e) => setNewLogo(e.target.value)}
                                                label={t('enterLogoUrl')}
                                                fullWidth
                                            />
                                        ) : (
                                            <img
                                                src={website.logo || placeholderLogo}
                                                alt={`${website.name} logo`}
                                                style={{ width: '50px', height: '50px', objectFit: 'contain', borderRadius: '8px' }}
                                                onError={(e) => { e.target.src = placeholderLogo; }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>{website.name}</TableCell>
                                    <TableCell>
                                        <a href={website.url} target="_blank" rel="noopener noreferrer">
                                            {website.url}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        {editingId === website.id ? (
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(website.id)}>
                                                {t('save')}
                                            </Button>
                                        ) : (
                                            <Button variant="outlined" color="secondary" onClick={() => handleEditClick(website.id, website.logo)}>
                                                {t('editLogo')}
                                            </Button>
                                        )}
                                        {editingId === website.id && (
                                            <Button variant="text" onClick={() => setEditingId(null)} sx={{ ml: 2 }}>
                                                {t('cancel')}
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 2 }}>
                    {t('noWebsitesAvailable')}
                </Typography>
            )}
        </Container>
    );
};

export default AdminWebsites;
