import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Pie, Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import api from '../sharedComponents/api';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend, BarElement);

const CustomerAnalytics = () => {
  const { t } = useTranslation();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await api.get('/admin/analytics');
        console.log('Fetched analytics:', response.data);
        setAnalytics(response.data);
      } catch (error) {
        console.error('Error fetching analytics:', error);
        setError(t('errorFetchingData'));
      } finally {
        setLoading(false);
      }
    };
    fetchAnalytics();
  }, [t]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <CircularProgress />
        <Typography>{t('loading')}</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!analytics) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography>{t('noDataAvailable')}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t('customerAnalytics')}
      </Typography>

      {/* Basic data confirmation */}
      <Typography>Total Admins: {analytics.total_admins}</Typography>
      <Typography>Total Non-Admins: {analytics.total_non_admins}</Typography>
      <Typography>Total Customers: {analytics.total_customers}</Typography>
      <Typography>Total Applications: {analytics.total_applications}</Typography>
      <Typography>Average Listings per Website: {analytics.average_listings_per_website}</Typography>

      {/* Total Customers - Pie Chart */}
      <Typography variant="h6" sx={{ mt: 4 }}>{t('Total Customers')}</Typography>
      <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
        <Pie
          data={{
            labels: [t('Admin'), t('Customer')],
            datasets: [
              {
                data: [analytics.total_admins || 0, analytics.total_non_admins || 0],
                backgroundColor: ['#FF6384', '#36A2EB'],
              },
            ],
          }}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>

      {/* Listings by Website - Bar Chart */}
      <Typography variant="h6" sx={{ mt: 4 }}>{t('Listings by Website')}</Typography>
      <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
        <Bar
          data={{
            labels: Object.keys(analytics.listings_by_website || {}),
            datasets: [
              {
                label: t('Listings by Website'),
                data: Object.values(analytics.listings_by_website || {}),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
              },
            ],
          }}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>

      {/* Applications by Website - Bar Chart */}
      <Typography variant="h6" sx={{ mt: 4 }}>{t('Applications by Website')}</Typography>
      <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
        <Bar
          data={{
            labels: Object.keys(analytics.applications_by_website || {}),
            datasets: [
              {
                label: t('Applications by Website'),
                data: Object.values(analytics.applications_by_website || {}),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
              },
            ],
          }}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
    </Container>
  );
};

export default CustomerAnalytics;
