// src/pages/PaymentSuccess.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <div className="container mt-5 text-center">
            <h2>Payment Successful!</h2>
            <p>Thank you for your purchase. Your package has been activated, and your account balance has been updated.</p>
            <button className="btn btn-primary" onClick={handleGoToDashboard}>
                Go to Dashboard
            </button>
        </div>
    );
};

export default PaymentSuccess;
