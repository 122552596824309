import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../sharedComponents/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Preferences.css';
import api from '../sharedComponents/api';

const bezirke = [
  'Charlottenburg-Wilmersdorf', 'Friedrichshain-Kreuzberg', 'Lichtenberg', 'Marzahn-Hellersdorf',
  'Mitte', 'Neukölln', 'Pankow', 'Reinickendorf', 'Spandau', 'Steglitz-Zehlendorf',
  'Tempelhof-Schöneberg', 'Treptow-Köpenick'
];

const Preferences = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    min_rooms: '',
    max_rooms: '',
    min_size: '',
    max_size: '',
    min_price: '',
    max_price: '',
    haswbs: 'bothwbs',
    location: [],
  });
  const [errors, setErrors] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await api.get('/preferences');
        if (response.data) {
          setFormData({
            min_rooms: response.data.min_rooms || '',
            max_rooms: response.data.max_rooms || '',
            min_size: response.data.min_size || '',
            max_size: response.data.max_size || '',
            min_price: response.data.min_price || '',
            max_price: response.data.max_price || '',
            haswbs: response.data.haswbs === true ? 'true' : response.data.haswbs === false ? 'false' : 'bothwbs',
            location: response.data.location ? response.data.location.split(',') : [],
          });
          setSelectAll(response.data.location?.length === bezirke.length);
        }
      } catch (error) {
        console.error(t('errorFetchingPreferences'), error);
      }
    };

    if (isAuthenticated) {
      fetchPreferences();
    }
  }, [isAuthenticated, t]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleLocationChange = (bezirk) => {
    let newLocation = [...formData.location];
    if (newLocation.includes(bezirk)) {
      newLocation = newLocation.filter((loc) => loc !== bezirk);
    } else {
      newLocation.push(bezirk);
    }
    setFormData({ ...formData, location: newLocation });
    setSelectAll(newLocation.length === bezirke.length);
    setErrors({ ...errors, location: '' });
  };

  const handleSelectAll = () => {
    setFormData({ ...formData, location: !selectAll ? bezirke : [] });
    setSelectAll(!selectAll);
  };

  const validateForm = () => {
    const newErrors = {};
    if (formData.min_rooms < 1) {
      newErrors.min_rooms = t('minRoomsValidation');
    }
    if (formData.max_rooms < 1) {
      newErrors.max_rooms = t('maxRoomsValidation');
    }
    if (formData.min_size < 1) {
      newErrors.min_size = t('minSizeValidation');
    }
    if (formData.max_size < 1) {
      newErrors.max_size = t('maxSizeValidation');
    }
    if (formData.min_price < 1) {
      newErrors.min_price = t('minPriceValidation');
    }
    if (formData.max_price < 1) {
      newErrors.max_price = t('maxPriceValidation');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
        const payload = {
            ...formData,
            location: formData.location // This should be an array, not a string
        };
        
        await api.put('/preferences', payload); // Use payload directly
        setSaveMessage(t('preferencesSaved'));
        setTimeout(() => {
            navigate('/customer-dashboard');
        }, 2000);
    } catch (error) {
        console.error(t('errorSavingPreferences'), error);
        setSaveMessage(t('failedToSavePreferences'));
    }
};


  return (
    <div className="container">
      <div className="profile-container">
        <h2>{t('preferences')}</h2>
        {saveMessage && <div className="alert alert-success">{saveMessage}</div>}

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">{t('minRooms')}</label>
              <input
                type="number"
                className={`form-control ${errors.min_rooms ? 'is-invalid' : ''}`}
                name="min_rooms"
                value={formData.min_rooms}
                onChange={handleInputChange}
                required
              />
              {errors.min_rooms && <div className="invalid-feedback">{errors.min_rooms}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">{t('maxRooms')}</label>
              <input
                type="number"
                className={`form-control ${errors.max_rooms ? 'is-invalid' : ''}`}
                name="max_rooms"
                value={formData.max_rooms}
                onChange={handleInputChange}
                required
              />
              {errors.max_rooms && <div className="invalid-feedback">{errors.max_rooms}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">{t('minSize')}</label>
              <input
                type="number"
                className={`form-control ${errors.min_size ? 'is-invalid' : ''}`}
                name="min_size"
                value={formData.min_size}
                onChange={handleInputChange}
                required
              />
              {errors.min_size && <div className="invalid-feedback">{errors.min_size}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">{t('maxSize')}</label>
              <input
                type="number"
                className={`form-control ${errors.max_size ? 'is-invalid' : ''}`}
                name="max_size"
                value={formData.max_size}
                onChange={handleInputChange}
                required
              />
              {errors.max_size && <div className="invalid-feedback">{errors.max_size}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">{t('minPrice')}</label>
              <input
                type="number"
                className={`form-control ${errors.min_price ? 'is-invalid' : ''}`}
                name="min_price"
                value={formData.min_price}
                onChange={handleInputChange}
                required
              />
              {errors.min_price && <div className="invalid-feedback">{errors.min_price}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">{t('maxPrice')}</label>
              <input
                type="number"
                className={`form-control ${errors.max_price ? 'is-invalid' : ''}`}
                name="max_price"
                value={formData.max_price}
                onChange={handleInputChange}
                required
              />
              {errors.max_price && <div className="invalid-feedback">{errors.max_price}</div>}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">{t('location')}</label>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="selectAll"
                checked={selectAll}
                onChange={handleSelectAll}
              />
              <label className="form-check-label" htmlFor="selectAll">{t('selectAll')}</label>
            </div>
            <div className="row">
              {bezirke.map((bezirk, index) => (
                <div key={index} className="col-sm-6 col-md-4">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`bezirk-${index}`}
                      value={bezirk}
                      checked={formData.location.includes(bezirk)}
                      onChange={() => handleLocationChange(bezirk)}
                    />
                    <label className="form-check-label" htmlFor={`bezirk-${index}`}>
                      {bezirk}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">{t('hasWBS')}</label>
            <div className="form-check">
              <input type="radio" className="form-check-input" name="haswbs" value="bothwbs" checked={formData.haswbs === 'bothwbs'} onChange={handleInputChange} />
              <label className="form-check-label">{t('bothwbs')}</label>
            </div>
            <div className="form-check">
              <input type="radio" className="form-check-input" name="haswbs" value="true" checked={formData.haswbs === 'true'} onChange={handleInputChange} />
              <label className="form-check-label">{t('yes')}</label>
            </div>
            <div className="form-check">
              <input type="radio" className="form-check-input" name="haswbs" value="false" checked={formData.haswbs === 'false'} onChange={handleInputChange} />
              <label className="form-check-label">{t('no')}</label>
            </div>
          </div>

          <button type="submit" className="btn btn-primary mt-3">{t('savePreferences')}</button>
        </form>
      </div>
    </div>
  );
};

export default Preferences;