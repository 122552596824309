// src/pages/PaymentFailure.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentFailure = () => {
    const navigate = useNavigate();

    const handleRetryPayment = () => {
        navigate('/payment');
    };

    return (
        <div className="container mt-5 text-center">
            <h2>Payment Failed</h2>
            <p>Unfortunately, your payment could not be processed. Please try again or contact support if the problem persists.</p>
            <button className="btn btn-primary" onClick={handleRetryPayment}>
                Retry Payment
            </button>
        </div>
    );
};

export default PaymentFailure;
