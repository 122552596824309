import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../sharedComponents/api';

function AdminDashboard() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm] = useState('');
  const [filters] = useState({
    admin: false,
    customer: false,
    paid: false,
    hasWbs: false,
    confirmed: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get(`/admin/users`);
        console.log('Fetched users:', response.data);
        if (Array.isArray(response.data)) {
          setUsers(response.data);
          setFilteredUsers(response.data);
        } else {
          setUsers([]);
        }
      } catch (error) {
        console.error(t('fetchUsersError'), error);
      }
    };
    fetchUsers();
  }, [t]);

  // Apply search and filters
  useEffect(() => {
    let filtered = users;

    if (searchTerm) {
      filtered = filtered.filter((user) =>
        user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filters.admin) {
      filtered = filtered.filter((user) => user.is_admin);
    }

    if (filters.customer) {
      filtered = filtered.filter((user) => !user.is_admin);
    }

    if (filters.paid) {
      filtered = filtered.filter((user) => user.has_paid);
    }

    if (filters.hasWbs) {
      filtered = filtered.filter((user) => user.hasWbs);
    }

    if (filters.confirmed) {
      filtered = filtered.filter((user) => user.email_confirmed);
    }

    setFilteredUsers(filtered);
  }, [searchTerm, filters, users]);


  const handleDelete = async (userId) => {
    const confirmDelete = window.confirm(t('confirmDelete'));
    if (confirmDelete) {
      try {
        await api.delete(`/admin/users/${userId}`);
        setUsers(users.filter(user => user.id !== userId));
      } catch (error) {
        console.error(t('deleteUserError'), error);
      }
    }
  };

  const handleEdit = (userId) => {
    navigate(`/admin/user/${userId}/edit`);
  };

  const handleViewListings = () => {
    navigate('/admin/listings');
  };

  const handleViewAnalytics = () => {
    navigate('/admin-analytics');
  };

  const handleAdminWebsite = () => {
    navigate('/admin/websites');
  };

  const handleViewServiceStatus = () => {
    navigate('/service-status');
  };
  const handleViewSchema = () => {
    navigate('/admin/schema');
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('adminDashboard')}
      </Typography>
      {/* Add a button to navigate to the Service Status page */}
      <Button
        variant="contained"
        color="secondary"
        sx={{ mb: 2 }}  // Add margin-bottom for spacing
        onClick={handleViewServiceStatus}  // Call the function to navigate
      >
        {t('viewServiceStatus')}  {/* Translated text for button */}
      </Button>
      <Button
        variant="contained"
        color="info"  // Adjust color as needed
        sx={{ mb: 2 }}
        onClick={handleViewSchema}  // Call the function to navigate
      >
        {t('viewSchema')}  {/* Translated text for the schema button */}
      </Button>

      {/* Add a button to navigate to the Service Status page */}
      <Button
        variant="contained"
        color="secondary"
        sx={{ mb: 2 }}  // Add margin-bottom for spacing
        onClick={handleAdminWebsite}  // Call the function to navigate
      >
        {t('viewWebsites')}  {/* Translated text for button */}
      </Button>

      {/* Add a button to navigate to the listings page */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2, mr: 2 }}  // Add some margin-right (mr) for spacing
        onClick={handleViewListings}  // Call the function to navigate
      >
        {t('viewListings')}  {/* Translated text for button */}
      </Button>

      {/* Add a button to navigate to the Customer Analytics page */}
      <Button
        variant="contained"
        color="primary"  // You can use a different color for the Analytics button
        sx={{ mb: 2 }}
        onClick={handleViewAnalytics}  // Call the function to navigate
      >
        {t('viewCustomerAnalytics')}  {/* Translated text for the analytics button */}
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('email')}</TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('role')}</TableCell>
              <TableCell>{t('lastLogin')}</TableCell> {/* New column for Last Login */}
              <TableCell align="right">{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.first_name} {user.last_name}</TableCell>
                  <TableCell>{user.is_admin ? t('admin') : t('user')}</TableCell>
                  <TableCell>
                    {user.last_login === '2100-01-01' ? t('neverLoggedIn') : user.last_login}
                  </TableCell>

                  <TableCell align="right">
                    <IconButton color="primary" aria-label={t('edit')} onClick={() => handleEdit(user.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color={user.last_login === '2100-01-01' ? 'error' : 'primary'} // Conditional color
                      aria-label={t('delete')}
                      onClick={() => handleDelete(user.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t('noUsersFound')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default AdminDashboard;
