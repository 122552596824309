import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="container mt-4">
      <h2>{t('aboutUsTitle')}</h2>
      <p>{t('welcome2')}</p>
      <p>{t('mission')}</p>
      <h4>{t('whyChoose')}</h4>
      <ul>
        <li>{t('bulletPoints.point1')}</li>
        <li>{t('bulletPoints.point2')}</li>
        <li>{t('bulletPoints.point3')}</li>
        <li>{t('bulletPoints.point4')}</li>
      </ul>
      <p>{t('contact')}</p>
      <p>{t('thankYou')}</p>
    </div>
  );
};

export default AboutUs;
