import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../sharedComponents/api';
import '../App.css';

const AdminAppliedListings = () => {
  const { t } = useTranslation();  // Initialize translation
  const [listings, setListings] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);  // Loading state
  const { id } = useParams();  // Get user ID from URL parameters
  const placeholderImage = process.env.PUBLIC_URL + '/germany-map_2.png';
  const emptyStateImage = process.env.PUBLIC_URL + '/not-available.svg';  // Path to 'not-available.svg'

  useEffect(() => {
    const fetchAdminAppliedListings = async () => {
      try {
        const response = await api.get(`/admin/users/${id}/applications`);
        if (response.data.length === 0) {
          setError(t('noApplicationsFound'));  // If no applications found
        } else {
          setListings(response.data);  // Set listings data
        }
      } catch (error) {
        console.error("Error fetching applied listings:", error);
        setError(t('errorFetchingListings'));  // Handle error
      } finally {
        setLoading(false);  // Set loading to false after fetching
      }
    };

    fetchAdminAppliedListings();
  }, [id, t]);

  if (loading) {
    return <p>{t('loading')}</p>;  // Show loading message while data is being fetched
  }

  return (
    <div className="container mt-4">
      <h2>{t('AppliedListings')}</h2>  {/* Translated section heading */}
      {error && <p className="text-danger">{error}</p>}  {/* Show error message if any */}

      {/* Empty State */}
      {!error && listings.length === 0 && (
        <div className="text-center my-5">
          <img src={emptyStateImage} alt="No Listings" className="img-fluid mb-3" style={{ maxWidth: '300px' }} />
          <p className="text-warning">{t('noListingsForUser')}</p>  {/* Translated empty state message */}
        </div>
      )}

      {listings.length > 0 && (
        <div className="row">
          {listings.map((listing, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className="card h-100">
                <img 
                  src={listing.image_url || placeholderImage } 
                  className="card-img-top" 
                  alt={listing.title} 
                />
                <div className="card-body">
                  <h5 className="card-title">{listing.title}</h5>
                  <p className="card-text">{listing.description}</p>
                  <p className="card-text"><strong>{t('price')}:</strong> {listing.price}</p>
                  <p className="card-text"><strong>{t('size')}:</strong> {listing.size}</p>
                  <p className="card-text"><strong>{t('rooms')}:</strong> {listing.rooms}</p>
                  <a 
                    href={listing.url} 
                    className="btn btn-link" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {t('moreDetails')}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminAppliedListings;
