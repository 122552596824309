import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1 className="mb-4">Privacy Policy</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>

      <h2>1. Introduction</h2>
      <p>
        HomieGo ("we," "us," or "our") is committed to protecting your privacy and safeguarding your personal data. This Privacy Policy outlines how we collect, use, share, and protect your information when you use our platform, including our website, mobile application, and other services (collectively, "Services"). This policy complies with the General Data Protection Regulation (GDPR) and applicable German data protection laws.
      </p>

      <h2>2. Data Controller</h2>
      <p>
        The data controller for the purposes of this policy is:
        <br />
        <strong>HomieGo</strong>
        <br />
        Address: [Insert Company Address, e.g., Berlin, Germany]
        <br />
        Email: [service@homiego.de]
      </p>

      <h2>3. Information We Collect</h2>
      <h3>3.1 Personal Data</h3>
      <p>We may collect the following personally identifiable information when you use our services:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Date of birth</li>
        <li>Gender</li>
        <li>Payment information (e.g., credit card details, PayPal)</li>
        <li>Government-issued documents (e.g., WBS details, identification numbers)</li>
        <li>Preferences for property listings and search criteria</li>
      </ul>

      <h3>3.2 Automatically Collected Data</h3>
      <p>We automatically collect certain information about your interaction with our platform:</p>
      <ul>
        <li>IP address</li>
        <li>Browser type and version</li>
        <li>Device information (e.g., device type, operating system)</li>
        <li>Usage data (e.g., pages visited, time spent)</li>
        <li>Cookies and similar tracking technologies (refer to our Cookie Policy)</li>
      </ul>

      <h2>4. How We Use Your Information</h2>
      <p>We process your data for the following purposes:</p>
      <ul>
        <li>Service Delivery: To provide, manage, and improve our services.</li>
        <li>User Experience: To personalize your experience based on your preferences and interactions.</li>
        <li>Communication: To send you service-related updates, respond to inquiries, and manage your account.</li>
        <li>Payments: To process payments, including billing and payment verification.</li>
        <li>Legal Compliance: To comply with legal requirements, such as record-keeping for tax and accounting purposes.</li>
        <li>Security: To monitor and protect against fraudulent activities and unauthorized access.</li>
        <li>Analytics and Improvements: To analyze user behavior and improve the functionality of our services.</li>
      </ul>

      <h2>5. Legal Basis for Data Processing</h2>
      <p>We process your personal data based on the following legal grounds:</p>
      <ul>
        <li><strong>Consent</strong>: When you provide explicit consent (e.g., for receiving marketing communications).</li>
        <li><strong>Contractual Necessity</strong>: To perform our contract with you (e.g., providing requested services).</li>
        <li><strong>Legal Obligation</strong>: To comply with legal requirements (e.g., record-keeping, fraud prevention).</li>
        <li><strong>Legitimate Interests</strong>: To pursue our legitimate interests, such as improving our services and ensuring their security, provided that your rights do not override these interests.</li>
      </ul>

      <h2>6. Sharing and Disclosure of Data</h2>
      <p>We do not sell your personal data. However, we may share your information under the following circumstances:</p>
      <ul>
        <li><strong>Service Providers</strong>: We use third-party service providers to perform services on our behalf (e.g., payment processors, cloud storage providers).</li>
        <li><strong>Legal Compliance</strong>: We may disclose your data if required by law or to protect the rights, safety, or property of HomieGo or others.</li>
        <li><strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of assets, your data may be transferred as part of that transaction.</li>
      </ul>

      <h2>7. Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, or destruction. These include encryption, secure data storage, and access control measures. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.</p>

      <h2>8. Data Retention</h2>
      <p>We retain your personal data for as long as necessary to fulfill the purposes outlined in this policy and comply with legal obligations. When data is no longer required, we securely delete or anonymize it.</p>

      <h2>9. Your Rights</h2>
      <p>As a resident of the EU, you have the following rights under GDPR:</p>
      <ul>
        <li><strong>Right to Access</strong>: You can request a copy of the data we hold about you.</li>
        <li><strong>Right to Rectification</strong>: You can request that we correct any inaccuracies in your data.</li>
        <li><strong>Right to Erasure</strong>: You can request the deletion of your data in certain circumstances.</li>
        <li><strong>Right to Restriction</strong>: You can request that we limit the processing of your data.</li>
        <li><strong>Right to Data Portability</strong>: You have the right to receive your data in a structured, commonly used format.</li>
        <li><strong>Right to Object</strong>: You can object to data processing based on legitimate interests.</li>
        <li><strong>Right to Withdraw Consent</strong>: You can withdraw your consent at any time for processing based on your consent.</li>
      </ul>

      <h2>10. International Data Transfers</h2>
      <p>We may transfer your data to servers located outside of the European Union (EU) or European Economic Area (EEA). In such cases, we ensure appropriate safeguards, such as Standard Contractual Clauses (SCCs), to protect your data.</p>

      <h2>11. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar technologies to provide a better user experience, analyze trends, and administer the site. For more information, please refer to our <strong>Cookie Policy</strong>.</p>

      <h2>12. Changes to This Privacy Policy</h2>
      <p>We may update this privacy policy from time to time. We will notify you of any material changes by posting the updated policy on our website or through other communication channels. The "Effective Date" at the top of this policy indicates when the latest version came into effect.</p>

      <h2>13. Contact Us</h2>
      <p>
        If you have any questions or concerns about this privacy policy or how we handle your data, please contact us:
        <br />
        <strong>HomieGo</strong>
        <br />
        Email: [service@homiego.de]
        <br />
        Address: [Insert Company Address, e.g., Berlin, Germany]
      </p>
    </div>
  );
};

export default PrivacyPolicy;
