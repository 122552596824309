import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';
import api from '../sharedComponents/api';

const Notifications = () => {
  const { t } = useTranslation();
  const [notificationPreference, setNotificationPreference] = useState('email');
  const [preferredLanguage, setPreferredLanguage] = useState('en');
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/user/notifications');
        if (response.status === 200) {
          setNotificationPreference(response.data.notificationPreference);
          setPreferredLanguage(response.data.preferredLanguage);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user notifications settings:', error);
        setLoading(false);
        setError(true);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedNotifications = {
      notification_preference: notificationPreference,
      preferred_language: preferredLanguage
    };
    try {
      const response = await api.post('/user/notifications', updatedNotifications);
      if (response.status === 200) {
        setShowAlert(true);
        setError(false);
        setTimeout(() => setShowAlert(false), 3000);
      }
    } catch (error) {
      console.error('Error updating notifications settings:', error);
      setError(true);
    }
  };

  if (loading) {
    return <div className="loading-text">{t('loading')}</div>;
  }

  return (
    <div className="container mt-4">
      <div className="notifications-container">
        <h2>{t('notifications')}</h2>

        {showAlert && (
          <div className="alert alert-success" role="alert" aria-live="assertive">
            {t('notificationsUpdated')}
          </div>
        )}

        {error && (
          <div className="alert alert-danger" role="alert" aria-live="assertive">
            {t('errorUpdatingNotifications')}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="notificationPreference" className="form-label">
              {t('notificationPreference')}
            </label>
            <select
              id="notificationPreference"
              className="form-select"
              value={notificationPreference}
              onChange={(e) => setNotificationPreference(e.target.value)}
            >
              <option value="email">{t('email')}</option>
              <option value="whatsapp">{t('whatsapp')}</option>
              <option value="both">{t('both')}</option>
              <option value="none">{t('none')}</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="preferredLanguage" className="form-label">
              {t('preferredLanguage')}
            </label>
            <select
              id="preferredLanguage"
              className="form-select"
              value={preferredLanguage}
              onChange={(e) => setPreferredLanguage(e.target.value)}
            >
              <option value="en">{t('english')}</option>
              <option value="de">{t('german')}</option>
              <option value="ar">{t('arabic')}</option>
              <option value="uk">{t('ukrainian')}</option>
              <option value="es">{t('spanish')}</option>
            </select>
          </div>

          <div className="alert alert-info">
            {t('manualApplyNotice')}
          </div>

          <button type="submit" className="btn btn-primary">{t('saveNotifications')}</button>
        </form>
      </div>
    </div>
  );
};

export default Notifications;
