import React from 'react';

const FAQ = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1 className="mb-4">Frequently Asked Questions (FAQ)</h1>
      <p>
        We are currently preparing a comprehensive FAQ section to address your common questions and concerns. Stay tuned for more updates in the near future.
      </p>
      <p>
        In the meantime, for immediate assistance, feel free to contact our support team at <strong>service@homiego.de</strong>.
      </p>
    </div>
  );
};

export default FAQ;
