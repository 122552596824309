import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';
import api from './api';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError(t('enterValidEmail'));
      return;
    }

    setLoading(true);
    setError('');

    try {
      await api.post('/forgot-password', { email });  // Use custom api instance

      setSuccess(true);
      setEmail('');
      alert(t('passwordResetEmailSent'));
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError(t('errorSendingPasswordResetEmail') + `: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <h2>{t('forgotPassword')}</h2>
      {error && <p className="text-danger">{error}</p>}
      {success && <p className="text-success">{t('passwordResetEmailSent')}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">{t('emailAddress')}</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loading}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            t('sendResetLink')
          )}
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
