import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useTranslation } from 'react-i18next';
import '../App.css';
import './Register.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons for password toggle

const Register = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('male');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [phoneNumber, setPhoneNumber] = useState('');
  const [numberOfPersons, setNumberOfPersons] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [gdprCompliance, setGdprCompliance] = useState(false); // GDPR compliance state

  const [validationErrors, setValidationErrors] = useState({});
  const [emailInUse, setEmailInUse] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  const { register } = useContext(AuthContext);
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const today = new Date();

    if (!firstName || !/^[A-Za-z]{2,}$/.test(firstName)) {
      errors.firstName = t('firstNameInvalid');
    }
    if (!lastName || !/^[A-Za-z]{2,}$/.test(lastName)) {
      errors.lastName = t('lastNameInvalid');
    }

    if (!birthDate) {
      errors.birthDate = t('birthDateRequired');
    } else if (new Date(birthDate) >= today) {
      errors.birthDate = t('birthDateInPast');
    }

    if (!email || !/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
      errors.email = t('emailInvalid');
    }

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@/,.!%*?&-])[A-Za-z\d@/,.!%*?&-]{8,}$/;
    if (!password || !passwordPattern.test(password)) {
      errors.password = t('passwordInvalid');
    }

    if (!numberOfPersons || isNaN(numberOfPersons) || numberOfPersons < 1) {
      errors.numberOfPersons = t('numberOfPersonsMin');
    }
    if (!gdprCompliance) {
      errors.gdprCompliance = t('gdprRequired');
    }

    return errors;
  };

  const checkPasswordCriteria = (password) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[@/,.!%*?&-]/.test(password),
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setLoading(false);
      return;
    }

    const formData = {
      first_name: firstName,
      last_name: lastName,
      birth_date: birthDate,
      gender: gender,
      email: email,
      password: password,
      phone_number: phoneNumber,
      number_of_persons: numberOfPersons,
      message: message,
      gdpr_compliance: gdprCompliance,
    };

    const registrationResult = await register(formData);

    if (registrationResult === "Email already registered") {
      setEmailInUse(true);
      setValidationErrors({ ...validationErrors, email: t('emailAlreadyRegistered') });
      setLoading(false);
    } else if (registrationResult === true) {
      navigate('/login', { state: { message: t('checkYourEmail') } });
    } else {
      console.log(t('registrationFailed'));
    }

    setLoading(false);
  };

  return (
    <div className="profile-container">
      <form onSubmit={handleRegister} className="mt-4">
        <h2>{t('register')}</h2>

        <div className="mb-3">
          <label className="form-label">{t('firstName')}*:</label>
          <input
            type="text"
            className={`form-control ${validationErrors.firstName ? 'is-invalid' : ''}`}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          {validationErrors.firstName && <div className="invalid-feedback">{validationErrors.firstName}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('lastName')}*:</label>
          <input
            type="text"
            className={`form-control ${validationErrors.lastName ? 'is-invalid' : ''}`}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          {validationErrors.lastName && <div className="invalid-feedback">{validationErrors.lastName}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('birthDate')}*:</label>
          <input
            type="date"
            className={`form-control ${validationErrors.birthDate ? 'is-invalid' : ''}`}
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            required
          />
          {validationErrors.birthDate && <div className="invalid-feedback">{validationErrors.birthDate}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('gender')}*:</label>
          <select className="form-control" value={gender} onChange={(e) => setGender(e.target.value)} required>
            <option value="male">{t('male')}</option>
            <option value="female">{t('female')}</option>
            <option value="other">{t('other')}</option>
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">{t('email')}*:</label>
          <input
            type="email"
            className={`form-control ${validationErrors.email || emailInUse ? 'is-invalid' : ''}`}
            value={email}
            onChange={(e) => { setEmail(e.target.value); setEmailInUse(false); }}
            required
          />
          {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
          {emailInUse && <div className="invalid-feedback">{t('emailAlreadyRegistered')}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('password')}*:</label>
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPasswordCriteria(e.target.value);
              }}
              required
            />
            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {validationErrors.password && <div className="invalid-feedback">{validationErrors.password}</div>}
          <div className="form-text">{t('passwordCriteria')}</div>
          <ul className="text-danger">
            <li className={passwordCriteria.length ? 'text-success' : ''}>{t('passwordMinLength')}</li>
            <li className={passwordCriteria.uppercase ? 'text-success' : ''}>{t('passwordUppercase')}</li>
            <li className={passwordCriteria.lowercase ? 'text-success' : ''}>{t('passwordLowercase')}</li>
            <li className={passwordCriteria.number ? 'text-success' : ''}>{t('passwordNumber')}</li>
            <li className={passwordCriteria.special ? 'text-success' : ''}>{t('passwordSpecial')}</li>
          </ul>
        </div>


        <div className="mb-3">
          <label className="form-label">{t('numberOfPersons')}*:</label>
          <input
            type="number"
            className={`form-control ${validationErrors.numberOfPersons ? 'is-invalid' : ''}`}
            value={numberOfPersons}
            onChange={(e) => setNumberOfPersons(e.target.value)}
            required
          />
          {validationErrors.numberOfPersons && (
            <div className="invalid-feedback">{validationErrors.numberOfPersons}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('phoneNumber')} ({t('optional')})</label>
          <input
            type="text"
            className={`form-control ${validationErrors.phoneNumber ? 'is-invalid' : ''}`}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">{t('message')} ({t('optional')})</label>
          <textarea
            className={`form-control ${validationErrors.message ? 'is-invalid' : ''}`}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        {/* GDPR Compliance Checkbox */}
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="gdprCompliance"
            checked={gdprCompliance}
            onChange={(e) => setGdprCompliance(e.target.checked)}
            required
          />
          <label className="form-check-label" htmlFor="gdprCompliance">
            <span>
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="gdpr-link"
              >
                {t('gdprAgreement')}
              </a>
            </span>
          </label>
          {validationErrors.gdprCompliance && <div className="invalid-feedback">{validationErrors.gdprCompliance}</div>}
        </div>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : t('register')}
        </button>
      </form>
    </div>
  );
};

export default Register;
