import React from 'react';

const Contact = () => {
  return (
    <div className="container mt-5 pt-5">
      <h1 className="mb-4">Contact Us</h1>
      <p>
        We are in the process of enhancing our communication channels to better serve you. More details will be available shortly. 
      </p>
      <p>
        In the meantime, if you have any inquiries, please feel free to reach out to us at <strong>service@homiego.de</strong>.
      </p>
    </div>
  );
};

export default Contact;
