import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../sharedComponents/api';
import { useTranslation } from 'react-i18next';

const packageOptions = {
  notification_30: { listing_count: 30, price: 7.5 },
  auto_apply_30: { listing_count: 30, price: 13.5 },
  notification_50: { listing_count: 50, price: 10.0 },
  auto_apply_50: { listing_count: 50, price: 15.0 },
  notification: { listing_count: 0, price: 0 },  // Generic notification, allows customization
  auto_apply: { listing_count: 0, price: 0 }     // Generic auto-apply, allows customization
};

const AdminPayments = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [payments, setPayments] = useState([]);
  const [newPayment, setNewPayment] = useState({ package_type: 'notification', listing_count: 0, amount: 0 });
  const [isCustomizable, setIsCustomizable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingPaymentId, setEditingPaymentId] = useState(null);
  const [userBalance, setUserBalance] = useState({ notification_balance: 0, auto_apply_balance: 0 }); // Add state for user balances

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await api.get(`/admin/users/${id}`);
      setUserBalance({
        notification_balance: response.data.notification_balance,
        auto_apply_balance: response.data.auto_apply_balance,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }, [id]);

  const fetchPayments = useCallback(async () => {
    try {
      const response = await api.get(`/admin/users/${id}/payments`);
      setPayments(response.data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchUserDetails();
    fetchPayments();
  }, [fetchUserDetails, fetchPayments]);

  const handlePackageSelect = (e) => {
    const packageType = e.target.value;
    if (packageType in packageOptions) {
      const selectedPackage = packageOptions[packageType];
      setNewPayment({
        package_type: packageType,
        listing_count: selectedPackage.listing_count,
        amount: selectedPackage.price
      });
      setIsCustomizable(packageType === 'notification' || packageType === 'auto_apply');
    }
  };

  const handleCreateOrUpdatePayment = async () => {
    try {
      const payload = {
        ...newPayment,
        listing_count: parseInt(newPayment.listing_count, 10),
        amount: parseFloat(newPayment.amount)
      };

      if (isEditing) {
        await api.put(`/admin/users/${id}/payments/${editingPaymentId}`, payload);
        setIsEditing(false);
        setEditingPaymentId(null);
      } else {
        await api.post(`/admin/users/${id}/payments`, payload);
      }

      setNewPayment({ package_type: 'notification', listing_count: 0, amount: 0 });
      fetchPayments();
    } catch (error) {
      console.error(isEditing ? "Error updating payment:" : "Error creating payment:", error);
    }
  };

  const handleEditPayment = (payment) => {
    setNewPayment({
      package_type: payment.package_type,
      listing_count: payment.listing_count,
      amount: payment.amount
    });
    setIsEditing(true);
    setEditingPaymentId(payment.id);
    setIsCustomizable(payment.package_type === 'notification' || payment.package_type === 'auto_apply');
  };

  const handleDeletePayment = async (paymentId) => {
    try {
      await api.delete(`/admin/users/${id}/payments/${paymentId}`);
      fetchPayments();
    } catch (error) {
      console.error("Error deleting payment:", error);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingPaymentId(null);
    setNewPayment({ package_type: 'notification', listing_count: 0, amount: 0 });
  };

  return (
    <div className="container mt-4">
      <h2 className="text-primary">{t('managePaymentsForUser')} {id}</h2>

      {/* Display Current User Balances */}
      <div className="mb-4">
        <h4>{t('currentBalances')}</h4>
        <p><strong>{t('notificationBalance')}:</strong> {userBalance.notification_balance}</p>
        <p><strong>{t('autoApplyBalance')}:</strong> {userBalance.auto_apply_balance}</p>
      </div>

      {/* Display Existing Payments */}
      <ul className="list-group mb-4">
        {payments.map(payment => (
          <li key={payment.id} className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              <strong className="text-info">{payment.package_type.replace('_', ' ')}</strong> - 
              <span className="text-success">€{payment.amount}</span> - 
              <span className="badge bg-secondary">{t(payment.payment_status)}</span>
            </span>
            <div>
              <button className="btn btn-primary btn-sm me-2" onClick={() => handleEditPayment(payment)}>
                {t('edit')}
              </button>
              <button className="btn btn-secondary btn-sm me-2" onClick={() => handleDeletePayment(payment.id)}>
                {t('delete')}
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Add/Edit Payment Form */}
      <h3 className="text-warning">{isEditing ? t('editPayment') : t('addPayment')}</h3>
      <div className="mb-3">
        <label className="form-label">{t('packageType')}</label>
        <select className="form-select text-primary" onChange={handlePackageSelect} value={newPayment.package_type}>
          {Object.keys(packageOptions).map(key => (
            <option key={key} value={key}>
              {key.replace('_', ' ')}
            </option>
          ))}
        </select>
      </div>

      {/* Allow customization for 'notification' and 'auto_apply' options */}
      {isCustomizable && (
        <>
          <div className="mb-3">
            <label className="form-label">{t('listingCount')}</label>
            <input
              type="number"
              className="form-control text-primary"
              placeholder={t('listingCount')}
              value={newPayment.listing_count}
              onChange={(e) => setNewPayment({ ...newPayment, listing_count: parseInt(e.target.value, 10) || 0 })}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('amount')}</label>
            <input
              type="number"
              className="form-control text-primary"
              placeholder="€0.00"
              value={newPayment.amount}
              onChange={(e) => setNewPayment({ ...newPayment, amount: parseFloat(e.target.value) || 0 })}
            />
          </div>
        </>
      )}

      <button className="btn btn-primary btn-sm me-2" onClick={handleCreateOrUpdatePayment}>
        {isEditing ? t('updatePayment') : t('addPayment')}
      </button>
      {isEditing && (
        <button className="btn btn-secondary" onClick={handleCancelEdit}>
          {t('cancel')}
        </button>
      )}

      <button className="btn btn-secondary btn-sm me-2" onClick={() => navigate(`/admin/user/${id}/edit`)}>
        {t('backToPreferences')}
      </button>
    </div>
  );
};

export default AdminPayments;
