import React, { useEffect, useState } from 'react';
import '../App.css';
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
} from '@mui/material';
import api from '../sharedComponents/api';

const ServiceStatus = () => {
  const [services, setServices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchServiceStatus = async () => {
      try {
        const response = await api.get('/services/status'); // Use api instance for request
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching service status:", error);
        setError('Error fetching service status');
      } finally {
        setLoading(false);
      }
    };
    fetchServiceStatus();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Service Status
      </Typography>

      <Paper sx={{ padding: 2 }}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <List>
            {Object.entries(services).map(([name, status]) => (
              <ListItem key={name}>
                <ListItemText
                  primary={name}
                  secondary={status === 'healthy' ? 'Operational' : 'Down'}
                  sx={{ color: status === 'healthy' ? 'green' : 'red' }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Container>
  );
};

export default ServiceStatus;
