import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons for password toggle
import '../App.css';
import './Login.css';

const Login = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, resendVerification, resetPassword } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.message) {
      setSuccess(location.state.message);
    }
  }, [location.state]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const response = await login(email, password);
      setLoading(false);

      if (response) {
        const { is_admin } = response;

        if (is_admin) {
          navigate('/admin-dashboard');
        } else if (!is_admin) {
          navigate('/customer-dashboard');
        }
      } else {
        setError(t('invalidCredentialsOrEmailIsNotConfirmed'));
      }
    }  catch (error) {
      console.error('Login error:', error);
      const message = error.response?.data?.message;
      if (message === "Account deleted") {
        setError(t('accountDeleted'));  // Add this translation key to handle deleted account
      } else {
        setError(t('invalidCredentialsOrEmailIsNotConfirmed'));
      }
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setError('');
    const success = await resendVerification(email);
    if (success) {
      setSuccess(t('verificationEmailResent'));
    } else {
      setError(t('errorResendingVerificationEmail'));
    }
  };

  const handleForgotPassword = async () => {
    setError('');
    const success = await resetPassword(email);
    if (success) {
      setSuccess(t('passwordResetEmailSent'));
    } else {
      setError(t('errorSendingPasswordResetEmail'));
    }
  };

  return (
    <div className="profile-container">
      <form onSubmit={handleLogin} className="mt-4">
        <h2>{t('login')}</h2>
        {success && <div className="alert alert-success">{success}</div>}
        {error && <div className="alert alert-danger">{error}</div>}

        <div className="mb-3">
          <label className="form-label">{t('email')}:</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{t('password')}:</label>
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
            />
            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
  {loading ? t('loading') : t('login')}
</button>


        {error.includes(t('emailNotConfirmed')) && (
          <button type="button" className="btn btn-secondary w-100" onClick={handleResendVerification}>
            {t('resendVerificationEmail')}
          </button>
        )}

        <div className="mt-3 inline-links">
          <button type="button" className="btn btn-link text-blue" onClick={handleForgotPassword} disabled={loading}>
            {t('forgotPassword')+' ?'}
          </button>
          <span style={{ color: 'black' }}>|</span>
          <button type="button" className="btn btn-link text-blue" onClick={() => navigate('/register')} disabled={loading}>
            {t('register')}
          </button>
        </div>

      </form>
    </div>
  );
};

export default Login;
