import React from 'react';
import './Footer.css';
import { FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* Company Info Section */}
          <div className="col-md-3">
            <h5>HomieGo</h5>
            <p>Your trusted partner in finding rental apartments across Germany.</p>
            <p>&copy; {new Date().getFullYear()} HomieGo. All rights reserved.</p>
          </div>

          {/* Quick Links Section */}
          <div className="col-md-3">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/about-us">About Us</a></li>
              <li><a href="/contact">Contact Us</a></li>
              <li><a href="/faq">FAQ</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
            </ul>
          </div>

          {/* Contact Information Section */}
          <div className="col-md-3">
            <h5>Contact</h5>
            <p>Email: <a href="mailto:service@homiego.de" className="text-light">service@homiego.de</a></p>
            <p>Phone: <a href="tel:+4917683217950" className="text-light">+49 176 83217950</a></p>
            <div className="social-icons">
              <a href="https://www.linkedin.com/in/king-shawamreh/" target="_blank" rel="noopener noreferrer" className="text-light">
                <FaLinkedin />
              </a>
            </div>
          </div>

          {/* Germany Map Section */}
          <div className="col-md-3">
            <img src="/germany-map.png" alt="Germany Map" className="footer-map" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
