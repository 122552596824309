import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Filter.css';

const bezirkToPLZ = {
  'Charlottenburg-Wilmersdorf': [10585, 10623, 10625, 10627, 10629, 10707, 10709, 10711, 10713, 10715, 14050, 14052, 14053, 14055, 14057, 14059],
  'Friedrichshain-Kreuzberg': [10243, 10245, 10247, 10249, 10961, 10963, 10965, 10967, 10969, 10997, 10999],
  'Lichtenberg': [10315, 10317, 10318, 10319, 10365, 10367, 10369, 13051, 13053, 13055, 13057, 13059],
  'Marzahn-Hellersdorf': [12619, 12621, 12623, 12627, 12629],
  'Mitte': [10115, 10117, 10119, 10178, 10179, 10435, 10551, 10553, 10555, 10557, 10559, 10785, 10787, 13347, 13349, 13351, 13353, 13355, 13357, 13359],
  'Neukölln': [12043, 12045, 12047, 12049, 12051, 12053, 12055, 12057, 12059],
  'Pankow': [10405, 10407, 10409, 10435, 10437, 10439, 13125, 13127, 13129, 13156, 13158, 13187, 13189],
  'Reinickendorf': [13403, 13405, 13407, 13409, 13435, 13437, 13439, 13465, 13467, 13469],
  'Spandau': [13581, 13583, 13585, 13587, 13589, 13591, 13593, 13595, 13597, 13599],
  'Steglitz-Zehlendorf': [12157, 12161, 12163, 12165, 12167, 12169, 12203, 12205, 12207, 12209, 12247, 12249],
  'Tempelhof-Schöneberg': [10777, 10779, 10781, 10783, 10785, 10787, 10789, 10823, 10825, 10827, 10829, 12101, 12103, 12105, 12107, 12109, 12157, 12159, 12161, 12163, 12165, 12167, 12169],
  'Treptow-Köpenick': [12435, 12437, 12439, 12459, 12487, 12489, 12524, 12526, 12527, 12529, 12555, 12557, 12559, 12587, 12589],
};

const Filters = ({ filters, handleFilterChange, applyFilters, setFilters }) => {
  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);
  const [locationVisible, setLocationVisible] = useState(false);

  const clearFilters = () => {
    setFilters({
      min_price: '',
      max_price: '',
      min_size: '',
      max_size: '',
      min_rooms: '',
      max_rooms: '',
      wbs: 'bothwbs',
      location: []
    });
    setSelectAll(false);
  };

  const handleLocationChange = (e) => {
    const { value, checked } = e.target;
    let newLocations = [...filters.location];

    if (value === 'All') {
      if (checked) {
        newLocations = Object.keys(bezirkToPLZ);
      } else {
        newLocations = [];
      }
      setSelectAll(checked);
    } else {
      if (checked) {
        newLocations = [...new Set([...newLocations, value])];
      } else {
        newLocations = newLocations.filter(loc => loc !== value);
      }
      setSelectAll(newLocations.length === Object.keys(bezirkToPLZ).length);
    }

    handleFilterChange({ target: { name: 'location', value: newLocations } });
  };

  const toggleLocation = () => {
    setLocationVisible(!locationVisible);
  };

  return (
    <div className="filters mb-4">
      <h5>{t('filters')}</h5>
      <div className="row">
        <div className="col-md-3">
          <label>{t('minPrice')}</label>
          <input type="number" className="form-control" name="min_price" value={filters.min_price} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('maxPrice')}</label>
          <input type="number" className="form-control" name="max_price" value={filters.max_price} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('minSize')}</label>
          <input type="number" className="form-control" name="min_size" value={filters.min_size} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('maxSize')}</label>
          <input type="number" className="form-control" name="max_size" value={filters.max_size} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('minRooms')}</label>
          <input type="number" className="form-control" name="min_rooms" value={filters.min_rooms} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('maxRooms')}</label>
          <input type="number" className="form-control" name="max_rooms" value={filters.max_rooms} onChange={handleFilterChange} />
        </div>
        <div className="col-md-3">
          <label>{t('wbs')}</label>
          <select className="form-control" name="wbs" value={filters.wbs} onChange={handleFilterChange}>
            <option value="bothwbs">{t('bothwbs')}</option>
            <option value="true">{t('yes')}</option>
            <option value="false">{t('no')}</option>
          </select>
        </div>
        <div className="col-md-12 mt-3">
          <button className="btn btn-secondary mb-2 location-toggle-btn" onClick={toggleLocation}>
            {t('selectLocation')} <span className={locationVisible ? 'arrow-up' : 'arrow-down'}></span>
          </button>
          {locationVisible && (
            <div className="location-checkboxes">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="selectAll"
                  value="All"
                  checked={selectAll}
                  onChange={handleLocationChange}
                />
                <label className="form-check-label" htmlFor="selectAll">{t('selectAll')}</label>
              </div>
              {Object.keys(bezirkToPLZ).map((bezirk, index) => (
                <div key={index} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`bezirk-${index}`}
                    value={bezirk}
                    checked={filters.location.includes(bezirk)}
                    onChange={handleLocationChange}
                  />
                  <label className="form-check-label" htmlFor={`bezirk-${index}`}>{bezirk}</label>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className="col-md-6 mt-3">
          <button className="btn btn-primary mt-4" onClick={applyFilters}>{t('applyFilters')}</button>
        </div>
        <div className="col-md-6 mt-3">
          <button className="btn btn-secondary mt-4" onClick={clearFilters}>{t('clearFilters')}</button>
        </div>
      </div>
    </div>
  );
};

export default Filters;