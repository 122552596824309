import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import api from '../sharedComponents/api';

function SchemaView() {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);  // Track if there's an error in loading

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const response = await api.get('/admin/schema', {
          responseType: 'blob',
        });

        if (response.status === 200) {
          const blob = response.data;
          const blobUrl = URL.createObjectURL(blob);
          console.log("Image URL:", blobUrl);  // Log URL to confirm it’s created
          setImageUrl(blobUrl);
        } else {
          console.error('Failed to fetch schema image:', response.status);
          setError('Failed to fetch schema image');
        }
      } catch (error) {
        console.error('Error fetching schema:', error);
        setError('Error fetching schema image');
      }
    };

    fetchSchema();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('dataModelVisualization')}
      </Typography>
      {imageUrl ? (
        <img src={imageUrl} alt="Database Schema" width="100%" style={{ borderRadius: '8px' }} />
      ) : (
        <p>{error || "Loading schema..."}</p>  // Display error message if exists
      )}
    </Container>
  );
}

export default SchemaView;
