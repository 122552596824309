import React, { useState } from 'react';
import './PaymentPage.css';
import { FaWhatsapp, FaRobot } from 'react-icons/fa';
import api from '../sharedComponents/api';

const PaymentPage = () => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(false);

    const packages = [
        { id: 1, type: 'notification_30', description: '30 listings via WhatsApp', price: 7.5, icon: <FaWhatsapp /> },
        { id: 2, type: 'auto_apply_30', description: '30 automatic applications', price: 13.5, icon: <FaRobot /> },
        { id: 3, type: 'notification_50', description: '50 listings via WhatsApp', price: 10, icon: <FaWhatsapp /> },
        { id: 4, type: 'auto_apply_50', description: '50 automatic applications', price: 15, icon: <FaRobot /> }
    ];

    const handlePackageSelection = (pkg) => {
        setSelectedPackage(pkg);
    };

    const handlePayment = async () => {
        if (!selectedPackage) {
            alert('Please select a package before proceeding.');
            return;
        }
        setLoading(true);
        try {
            const response = await api.post('/paypal/create-payment', {
                package_type: selectedPackage.type
            });

            // Redirect to PayPal approval URL
            window.location.href = response.data.approvalUrl;
        } catch (error) {
            console.error('Error creating PayPal payment:', error);
            alert('Error initiating payment. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="payment-container mt-4">
            <h2 className="payment-title">Select a Package</h2>
            <div className="package-row">
                {packages.map((pkg) => (
                    <div
                        key={pkg.id}
                        className={`package-card ${selectedPackage?.id === pkg.id ? 'selected' : ''}`}
                        onClick={() => handlePackageSelection(pkg)}
                    >
                        <div className="package-content">
                            <div className="package-icon">{pkg.icon}</div>
                            <h5 className="package-title">{pkg.description}</h5>
                            <p className="package-price">Price: €{pkg.price}</p>
                        </div>
                    </div>
                ))}
            </div>
            <button
                className="payment-button"
                onClick={handlePayment}
                disabled={!selectedPackage || loading}
            >
                {loading ? 'Processing...' : 'Proceed to PayPal'}
            </button>
        </div>
    );
};

export default PaymentPage;
