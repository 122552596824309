import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons
import '../App.css';
import './Register.css'; // Import shared CSS
import api from './api';

const PasswordReset = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  // Function to update password criteria
  const checkPasswordCriteria = (password) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[@/,.!%*?&-]/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordCriteria(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError(t('passwordsDoNotMatch'));
      return;
    }

    setLoading(true);
    setError('');

    try {
      await api.post('/reset_password', { token, password }); // Use api instead of axios


      setSuccess(true);
      alert(t('passwordResetSuccess'));
      navigate('/login');
    } catch (error) {
      console.error('Password reset failed:', error);
      setError(t('errorResettingPassword') + `: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <h2>{t('resetPassword')}</h2>
      {error && <p className="text-danger">{error}</p>}
      {success && <p className="text-success">{t('passwordResetSuccess')}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">{t('newPassword')}</label>
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="form-text">{t('passwordCriteria')}</div>
          <ul className="text-danger">
            <li className={passwordCriteria.length ? 'text-success' : ''}>{t('passwordMinLength')}</li>
            <li className={passwordCriteria.uppercase ? 'text-success' : ''}>{t('passwordUppercase')}</li>
            <li className={passwordCriteria.lowercase ? 'text-success' : ''}>{t('passwordLowercase')}</li>
            <li className={passwordCriteria.number ? 'text-success' : ''}>{t('passwordNumber')}</li>
            <li className={passwordCriteria.special ? 'text-success' : ''}>{t('passwordSpecial')}</li>
          </ul>
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">{t('confirmPassword')}</label>
          <div className="password-container">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="form-control"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            <span className="password-toggle" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loading}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            t('resetPassword')
          )}
        </button>
      </form>
    </div>
  );
};

export default PasswordReset;
