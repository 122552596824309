import React, { useState, useEffect } from 'react';
import './AppliedListings.css';
import { useTranslation } from 'react-i18next';
import api from '../sharedComponents/api';

const AppliedListings = () => {
  const { t } = useTranslation();
  const [listings, setListings] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const placeholderImage = process.env.PUBLIC_URL + '/germany-map_2.png';
  const emptyStateImage = process.env.PUBLIC_URL + '/not-available.svg';  // Path to 'not-available.svg'

  useEffect(() => {
    const fetchAppliedListings = async () => {
      setLoading(true);

      try {
        const response = await api.get('/applied-listings');
        console.log('API Response:', response.data);

        if (response.data && response.data.listings) {
          setListings(response.data.listings);
        } else {
          setListings([]);
        }
        setLoading(false);
      } catch (error) {
        console.error(t('errorFetchingListings'), error);
        setError(t('errorFetchingListings'));
        setLoading(false);
      }
    };

    fetchAppliedListings();
  }, [t]);

  return (
    <div className="container mt-4">
      <h2 className="mb-4">{t('appliedListings')}</h2>

      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{t('loading')}</span>
          </div>
        </div>
      ) : error ? (
        <p className="text-danger text-center">{error}</p>
      ) : listings.length > 0 ? (
        <div className="row">
          {listings.map((listing, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className="card h-100 listing-card">
                <div className="image-container">
                  <img
                    src={listing.image_url || placeholderImage}
                    className="card-img-top"
                    alt={listing.title}
                    onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage }}
                  />
                  <img
                    src={listing.website_logo || placeholderImage}
                    alt="Website Logo"
                    className="company-logo-overlay"
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{listing.title}</h5>
                  <p className="card-text">{listing.description}</p>
                  <p className="card-text"><strong>{t('price')}:</strong> {listing.price} €</p>
                  <p className="card-text"><strong>{t('size')}:</strong> {listing.size} m²</p>
                  <p className="card-text"><strong>{t('rooms')}:</strong> {listing.rooms}</p>
                  <a
                    href={listing.url}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('moreDetails')}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center my-5">
          <img src={emptyStateImage} alt="No Listings" className="img-fluid mb-3" style={{ maxWidth: '300px' }} />
          <p className="text-warning">{t('noAppliedListings')}</p>  {/* Translated empty state message */}
        </div>
      )}
    </div>
  );
}

export default AppliedListings;
